import { takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '@actions/index';
import {
  activateAccountSaga,
  changeAccountPasswordSaga,
  createAccountSaga,
  createAccountInviteSaga,
  fetchAccountsSaga,
  fetchAvailablePermissionsSaga,
  updateAccountPermissionsSaga,
} from './accounts';
import {
  createAssetSaga,
  deleteAssetSaga,
  updateAssetPreCheckSaga,
  updateAssetSaga,
  fetchAssetsSaga,
  fetchAssetTicketSaga,
  fetchAssetTicketsSaga,
  createAssetTicketSaga,
  createAssetTicketCommentSaga,
  assignAssetTicketSaga,
  changeAssetTicketStatusSaga,
  archiveAssetTicketSaga,
  createAssetTicketOperationSaga,
  deleteAssetTicketOperationSaga,
  fetchAssetDocumentsSaga,
  createAssetDocumentSaga,
  deleteAssetDocumentSaga,
} from './assets';
import {
  accountSetupSaga,
  accountVerificationSaga,
  changeModeSaga,
  fetchAccountSaga,
  passwordRecoverySaga,
  passwordResetSaga,
  loginSaga,
  logoutSaga,
  signUpSaga,
  updateAccountContactsSaga,
  updateAccountPasswordSaga,
  updateAccountReportSaga,
  updateAccountSettingsSaga,
  deleteAccountSaga,
} from './authentication';
import {
  createConfigFileSaga,
  deleteConfigFileSaga,
  fetchConfigFilesSaga,
  updateConfigFileSaga,
} from './configurations';
import {
  createMessageSaga,
  fetchMessagesSaga,
} from './chats';
import {
  createDiagramSaga,
  deleteDiagramSaga,
  fetchDiagramsSaga,
  updateDiagramSaga,
} from './diagrams';
import {
  createPlanSaga,
  deletePlanSaga,
  fetchPlansSaga,
  updatePlanSaga,
} from './plans';
import {
  createEmployeeSaga,
  deleteEmployeeSaga,
  fetchEmployeesSaga,
  updateEmployeeSaga,
} from './employees';
import { fetchEventsActivitySaga } from './player';
import {
  createFloorplanSaga,
  deleteFloorplanSaga,
  fetchFloorplanSaga,
  updateFloorplanSaga,
} from './floorplans';
import {
  fetchItemsWithLocationsSaga,
  importItemsSaga,
  importStocksSaga,
} from './items';
import {
  createMaintenanceSaga,
  fetchMaintenancesByAssetSaga,
} from './maintenances';
import {
  dismissNotificationsSaga,
  fetchNotificationsCountSaga,
  fetchNotificationsSaga,
  unreadNotificationsSaga,
} from './notifications';
import { fetchOrdersWithItemsSaga, fetchOrdersEventsSaga, importOrdersSaga } from './orders';
import { fetchOrganisationInformationSaga } from './organisation';
import { fetchDataRangeSaga, fetchMetricsSaga, fetchMetricSaga } from './plots';
import {
  createPreCheckAnswerSaga,
  createPreCheckSaga,
  deletePreCheckSaga,
  editPreCheckSaga,
  fetchPreCheckAnswersByAssetSaga,
  fetchPreCheckListSaga,
  fetchPreCheckSaga,
} from './prechecks';
import { fetchReportSaga, fetchROIReportSaga } from './reports';
import {
  activateRuleSaga,
  createRuleSaga,
  deactivateRuleSaga,
  deleteRuleSaga,
  fetchRuleAlertsSaga,
  fetchRulesSaga,
  fetchTemplatesSaga,
  updateRuleSaga,
} from './rules';
import {
  fetchFloorplanSensorGroupStatusSaga,
  fetchFloorplanSensorsSaga,
  fetchFloorplanSensorsStatusSaga,
  fetchSensorGroupsByFloorplanSaga,
  fetchSensorInfoSaga,
  fetchSensorsDataSaga,
  createSensorSaga,
} from './sensors';
import { createShiftSaga, deleteShiftSaga, fetchShiftsSaga, updateShiftSaga } from './shifts';
import { createTaskSaga, deleteTaskSaga, fetchTasksSaga, updateTaskSaga } from './tasks';
import {
  createSimulationSaga,
  deleteSimulationSaga,
  fetchSimulationDataSaga,
  fetchSimulationSaga,
  fetchSimulationsSaga,
  fetchRoutingDataSaga,
  createRoutingPathsSaga,
  stopSimulationSaga,
} from './simulations';
import {
  createTeamSaga,
  deleteTeamSaga,
  fetchTeamsSaga,
  updateTeamSaga,
} from './teams';
import {
  createTicketSaga,
  updateTicketSaga,
  archiveTicketSaga,
  deleteTicketSaga,
  fetchTicketsSaga,
  createTicketCommentSaga,
  deleteTicketCommentSaga,
} from './tickets';
import {
  createWarehouseSaga,
  deleteAllWarehousesSaga,
  deleteWarehouseSaga,
  fetchWarehousesSaga,
  updateWarehouseSaga,
} from './warehouses';
import { createZoneSaga, deleteZoneSaga, updateZoneSaga } from './zones';

export default function* saga() {
  yield takeLatest(
    getType(actions.accountVerification.request),
    accountVerificationSaga
  );
  yield takeLatest(
    getType(actions.accountSetup.request),
    accountSetupSaga
  );
  yield takeLatest(getType(actions.changeMode.request), changeModeSaga);
  yield takeLatest(getType(actions.fetchAccount.request), fetchAccountSaga);
  yield takeLatest(
    getType(actions.passwordRecovery.request),
    passwordRecoverySaga
  );
  yield takeLatest(getType(actions.passwordReset.request), passwordResetSaga);
  yield takeLatest(getType(actions.login.request), loginSaga);
  yield takeLatest(getType(actions.logout.request), logoutSaga);
  yield takeLatest(getType(actions.signUp.request), signUpSaga);
  yield takeLatest(
    getType(actions.fetchAvailablePermissions.request),
    fetchAvailablePermissionsSaga
  );
  yield takeLatest(getType(actions.fetchAccounts.request), fetchAccountsSaga);
  yield takeLatest(
    getType(actions.activateAccount.request),
    activateAccountSaga
  );
  yield takeLatest(getType(actions.createAccount.request), createAccountSaga);
  yield takeLatest(getType(actions.createAccountInvite.request), createAccountInviteSaga);
  yield takeLatest(
    getType(actions.updateAccountContacts.request),
    updateAccountContactsSaga
  );
  yield takeLatest(
    getType(actions.changeAccountPassword.request),
    changeAccountPasswordSaga
  );
  yield takeLatest(
    getType(actions.updateAccountPermissions.request),
    updateAccountPermissionsSaga
  );
  yield takeLatest(
    getType(actions.createConfigFile.request),
    createConfigFileSaga
  );
  yield takeLatest(
    getType(actions.deleteConfigFile.request),
    deleteConfigFileSaga
  );
  yield takeLatest(
    getType(actions.fetchConfigFiles.request),
    fetchConfigFilesSaga
  );
  yield takeLatest(
    getType(actions.updateConfigFile.request),
    updateConfigFileSaga
  );
  yield takeLatest(
    getType(actions.createMessage.request),
    createMessageSaga
  );
  yield takeLatest(
    getType(actions.fetchMessages.request),
    fetchMessagesSaga
  );
  yield takeLatest(getType(actions.createDiagram.request), createDiagramSaga);
  yield takeLatest(getType(actions.deleteDiagram.request), deleteDiagramSaga);
  yield takeLatest(getType(actions.fetchDiagrams.request), fetchDiagramsSaga);
  yield takeLatest(getType(actions.updateDiagram.request), updateDiagramSaga);
  yield takeLatest(getType(actions.createPlan.request), createPlanSaga);
  yield takeLatest(getType(actions.deletePlan.request), deletePlanSaga);
  yield takeLatest(getType(actions.fetchPlans.request), fetchPlansSaga);
  yield takeLatest(getType(actions.updatePlan.request), updatePlanSaga);
  yield takeEvery(
    getType(actions.fetchEventsActivity.request),
    fetchEventsActivitySaga
  );
  yield takeLatest(
    getType(actions.updateAccountPassword.request),
    updateAccountPasswordSaga
  );
  yield takeLatest(
    getType(actions.updateAccountReport.request),
    updateAccountReportSaga
  );
  yield takeLatest(
    getType(actions.updateAccountSettings.request),
    updateAccountSettingsSaga
  );
  yield takeLatest(
    getType(actions.deleteAccount.request),
    deleteAccountSaga
  );
  yield takeLatest(
    getType(actions.fetchWarehouses.request),
    fetchWarehousesSaga
  );
  yield takeEvery(getType(actions.createEmployee.request), createEmployeeSaga);
  yield takeEvery(getType(actions.deleteEmployee.request), deleteEmployeeSaga);
  yield takeEvery(getType(actions.fetchEmployees.request), fetchEmployeesSaga);
  yield takeEvery(getType(actions.updateEmployee.request), updateEmployeeSaga);
  yield takeEvery(getType(actions.createShift.request), createShiftSaga);
  yield takeEvery(getType(actions.deleteShift.request), deleteShiftSaga);
  yield takeEvery(getType(actions.fetchShifts.request), fetchShiftsSaga);
  yield takeEvery(getType(actions.updateShift.request), updateShiftSaga);
  yield takeEvery(getType(actions.createTask.request), createTaskSaga);
  yield takeEvery(getType(actions.deleteTask.request), deleteTaskSaga);
  yield takeEvery(getType(actions.fetchTasks.request), fetchTasksSaga);
  yield takeEvery(getType(actions.updateTask.request), updateTaskSaga);
  yield takeEvery(getType(actions.createTeam.request), createTeamSaga);
  yield takeEvery(getType(actions.deleteTeam.request), deleteTeamSaga);
  yield takeEvery(getType(actions.fetchTeams.request), fetchTeamsSaga);
  yield takeEvery(getType(actions.updateTeam.request), updateTeamSaga);

  yield takeEvery(
    getType(actions.createFloorplan.request),
    createFloorplanSaga
  );
  yield takeLatest(
    getType(actions.createWarehouse.request),
    createWarehouseSaga
  );
  yield takeEvery(
    getType(actions.updateWarehouse.request),
    updateWarehouseSaga
  );
  yield takeEvery(
    getType(actions.deleteWarehouse.request),
    deleteWarehouseSaga
  );
  yield takeLatest(
    getType(actions.deleteAllWarehouses.request),
    deleteAllWarehousesSaga
  );
  yield takeEvery(
    getType(actions.fetchFloorplanSensorGroupStatus.request),
    fetchFloorplanSensorGroupStatusSaga
  );
  yield takeEvery(
    getType(actions.fetchFloorplanSensors.request),
    fetchFloorplanSensorsSaga
  );
  yield takeEvery(
    getType(actions.fetchFloorplanSensorsStatus.request),
    fetchFloorplanSensorsStatusSaga
  );
  yield takeLatest(
    getType(actions.fetchSensorGroupsByFloorplan.request),
    fetchSensorGroupsByFloorplanSaga
  );
  yield takeEvery(
    getType(actions.fetchSensorInfo.request),
    fetchSensorInfoSaga
  );
  yield takeEvery(
    getType(actions.fetchSensorsData.request),
    fetchSensorsDataSaga
  );
  yield takeEvery(
    getType(actions.createSensor.request),
    createSensorSaga
  );
  yield takeLatest(
    getType(actions.fetchSimulation.request),
    fetchSimulationSaga
  );
  yield takeEvery(
    getType(actions.fetchSimulationData.request),
    fetchSimulationDataSaga
  );
  yield takeLatest(
    getType(actions.fetchSimulations.request),
    fetchSimulationsSaga
  );
  yield takeEvery(
    getType(actions.fetchRoutingData.request),
    fetchRoutingDataSaga
  );
  yield takeEvery(
    getType(actions.createRoutingPaths.request),
    createRoutingPathsSaga
  );
  yield takeLatest(
    getType(actions.createSimulation.request),
    createSimulationSaga
  );
  yield takeLatest(
    getType(actions.deleteSimulation.request),
    deleteSimulationSaga
  );
  yield takeLatest(getType(actions.stopSimulation.request), stopSimulationSaga);
  yield takeLatest(getType(actions.createTicket.request), createTicketSaga);
  yield takeLatest(getType(actions.updateTicket.request), updateTicketSaga);
  yield takeLatest(getType(actions.archiveTicket.request), archiveTicketSaga);
  yield takeLatest(getType(actions.deleteTicket.request), deleteTicketSaga);
  yield takeLatest(getType(actions.fetchTickets.request), fetchTicketsSaga);
  yield takeLatest(
    getType(actions.createTicketComment.request),
    createTicketCommentSaga
  );
  yield takeLatest(
    getType(actions.deleteTicketComment.request),
    deleteTicketCommentSaga
  );
  yield takeEvery(
    getType(actions.deleteFloorplan.request),
    deleteFloorplanSaga
  );
  yield takeEvery(getType(actions.fetchFloorplan.request), fetchFloorplanSaga);
  yield takeEvery(
    getType(actions.updateFloorplan.request),
    updateFloorplanSaga
  );
  yield takeEvery(
    getType(actions.fetchItemsWithLocations.request),
    fetchItemsWithLocationsSaga
  );
  yield takeEvery(getType(actions.importItems.request), importItemsSaga);
  yield takeEvery(getType(actions.importStocks.request), importStocksSaga);
  yield takeLatest(getType(actions.createZone.request), createZoneSaga);
  yield takeLatest(getType(actions.deleteZone.request), deleteZoneSaga);
  yield takeLatest(getType(actions.updateZone.request), updateZoneSaga);
  yield takeLatest(getType(actions.deleteRule.request), deleteRuleSaga);
  yield takeLatest(getType(actions.fetchTemplates.request), fetchTemplatesSaga);
  yield takeLatest(getType(actions.updateRule.request), updateRuleSaga);
  yield takeLatest(getType(actions.fetchReport.request), fetchReportSaga);
  yield takeLatest(getType(actions.fetchROIReport.request), fetchROIReportSaga);
  yield takeLatest(
    getType(actions.createPreCheckAnswer.request),
    createPreCheckAnswerSaga
  );
  yield takeLatest(getType(actions.createPreCheck.request), createPreCheckSaga);
  yield takeLatest(getType(actions.deletePreCheck.request), deletePreCheckSaga);
  yield takeLatest(getType(actions.editPreCheck.request), editPreCheckSaga);
  yield takeLatest(
    getType(actions.fetchPreCheckAnswersByAsset.request),
    fetchPreCheckAnswersByAssetSaga
  );
  yield takeLatest(
    getType(actions.fetchPreCheckList.request),
    fetchPreCheckListSaga
  );
  yield takeLatest(getType(actions.fetchPreCheck.request), fetchPreCheckSaga);
  yield takeLatest(getType(actions.activateRule.request), activateRuleSaga);
  yield takeLatest(getType(actions.createRule.request), createRuleSaga);
  yield takeLatest(getType(actions.deactivateRule.request), deactivateRuleSaga);
  yield takeLatest(getType(actions.fetchRules.request), fetchRulesSaga);
  yield takeLatest(getType(actions.createAsset.request), createAssetSaga);
  yield takeLatest(getType(actions.deleteAsset.request), deleteAssetSaga);
  yield takeLatest(
    getType(actions.updateAssetPreCheck.request),
    updateAssetPreCheckSaga
  );
  yield takeLatest(getType(actions.updateAsset.request), updateAssetSaga);
  yield takeLatest(getType(actions.fetchAssets.request), fetchAssetsSaga);
  yield takeLatest(
    getType(actions.fetchAssetTicket.request),
    fetchAssetTicketSaga
  );
  yield takeLatest(
    getType(actions.fetchAssetTickets.request),
    fetchAssetTicketsSaga
  );
  yield takeLatest(
    getType(actions.createAssetTicket.request),
    createAssetTicketSaga
  );
  yield takeLatest(
    getType(actions.createAssetTicketComment.request),
    createAssetTicketCommentSaga
  );
  yield takeLatest(
    getType(actions.assignAssetTicket.request),
    assignAssetTicketSaga
  );
  yield takeLatest(
    getType(actions.changeAssetTicketStatus.request),
    changeAssetTicketStatusSaga
  );
  yield takeLatest(
    getType(actions.archiveAssetTicket.request),
    archiveAssetTicketSaga
  );
  yield takeLatest(
    getType(actions.createAssetTicketOperation.request),
    createAssetTicketOperationSaga
  );
  yield takeLatest(
    getType(actions.deleteAssetTicketOperation.request),
    deleteAssetTicketOperationSaga
  );
  yield takeLatest(
    getType(actions.fetchAssetDocuments.request),
    fetchAssetDocumentsSaga
  );
  yield takeLatest(
    getType(actions.createAssetDocument.request),
    createAssetDocumentSaga
  );
  yield takeLatest(
    getType(actions.deleteAssetDocument.request),
    deleteAssetDocumentSaga
  );
  yield takeLatest(
    getType(actions.createMaintenance.request),
    createMaintenanceSaga
  );
  yield takeLatest(
    getType(actions.fetchMaintenancesByAsset.request),
    fetchMaintenancesByAssetSaga
  );
  yield takeEvery(
    getType(actions.dismissNotifications.request),
    dismissNotificationsSaga
  );
  yield takeLatest(
    getType(actions.fetchNotifications.request),
    fetchNotificationsSaga
  );
  yield takeLatest(
    getType(actions.fetchNotificationsCount.request),
    fetchNotificationsCountSaga
  );
  yield takeEvery(
    getType(actions.unreadNotifications.request),
    unreadNotificationsSaga
  );
  yield takeEvery(
    getType(actions.fetchRuleAlerts.request),
    fetchRuleAlertsSaga
  );
  yield takeLatest(
    getType(actions.fetchOrdersWithItems.request),
    fetchOrdersWithItemsSaga
  );
  yield takeLatest(
    getType(actions.fetchOrdersEvents.request),
    fetchOrdersEventsSaga
  );
  yield takeLatest(getType(actions.importOrders.request), importOrdersSaga);
  yield takeLatest(
    getType(actions.fetchOrganisationInformation.request),
    fetchOrganisationInformationSaga
  );
  yield takeEvery(getType(actions.fetchDataRange.request), fetchDataRangeSaga);
  yield takeEvery(getType(actions.fetchMetrics.request), fetchMetricsSaga);
  yield takeEvery(getType(actions.fetchMetric.request), fetchMetricSaga);
}
