import { combineReducers } from 'redux';

import accounts, { IAccountsState } from './accounts';
import actionFeedback, { IActionFeedbackState } from './actionFeedback';
import app, { IAppState as IAppCState } from './app';
import assets, { IAssetsState } from './assets';
import authentication, { IAuthenticationState } from './authentication';
import chats, { IChatsState } from './chats';
import configurations, { IConfigurationState } from './configurations';
import diagrams, { IDiagramsState } from './diagrams';
import employees, { IEmployeesState } from './employees';
import shifts, { IShiftsState } from './shifts';
import player, { IPlayerState } from './player';
import filter, { IFilterState } from './filter';
import floorplans, { IFloorplansState } from './floorplans';
import items, { IItemsState } from './items';
import maintenances, { IMaintenanceState } from './maintenances';
import notifications, { INotificationsState } from './notifications';
import orders, { IOrdersState } from './orders';
import organisation, { IOrganisationState } from './organisation';
import analytics, { IAnalyticsState } from './analytics';
import plans, { IPlansState } from './plans';
import prechecks, { IPreCheckState } from './prechecks';
import reports, { IReportsState } from './reports';
import rules, { IRulesState } from './rules';
import sensors, { ISensorsState } from './sensors';
import shallowLoading, { IShallowLoadingState } from './shallow-loading';
import simulations, { ISimulationsState } from './simulations';
import tasks, { ITasksState } from './tasks';
import teams, { ITeamsState } from './teams';
import tickets, { ITicketsState } from './tickets';
import warehouses, { IWarehousesState } from './warehouses';
import zones, { IZonesState } from './zones';

export interface IAppState {
  accounts: IAccountsState;
  actionFeedback: IActionFeedbackState;
  app: IAppCState;
  analytics: IAnalyticsState;
  assets: IAssetsState;
  authentication: IAuthenticationState;
  configurations: IConfigurationState;
  chats: IChatsState;
  diagrams: IDiagramsState;
  employees: IEmployeesState;
  shifts: IShiftsState;
  player: IPlayerState;
  filter: IFilterState;
  floorplans: IFloorplansState;
  items: IItemsState;
  maintenances: IMaintenanceState;
  notifications: INotificationsState;
  orders: IOrdersState;
  organisation: IOrganisationState;
  prechecks: IPreCheckState;
  plans: IPlansState;
  reports: IReportsState;
  rules: IRulesState;
  sensors: ISensorsState;
  shallowLoading: IShallowLoadingState;
  simulations: ISimulationsState;
  tasks: ITasksState,
  teams: ITeamsState,
  tickets: ITicketsState;
  warehouses: IWarehousesState;
  zones: IZonesState;
}

export default combineReducers({
  accounts,
  actionFeedback,
  app,
  analytics,
  assets,
  authentication,
  chats,
  configurations,
  diagrams,
  employees,
  player,
  filter,
  floorplans,
  items,
  maintenances,
  notifications,
  orders,
  organisation,
  plans,
  prechecks,
  reports,
  rules,
  sensors,
  shallowLoading,
  shifts,
  simulations,
  tasks,
  teams,
  tickets,
  warehouses,
  zones,
});
