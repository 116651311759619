import { createAction, createAsyncAction } from 'typesafe-actions';

import { Orders } from '@models/Order';
import { IFailed, IFailedWithShallow, IImport } from './types';
import FetchError from '@models/FetchError';
import { IOrdersEvents } from '@api/websocket';

export enum OrdersTypes {
  FETCH_ORDERS_WITHITEMS = '@@orders/FETCH_ORDERS_WITHITEMS',
  FETCH_ORDERS_WITHITEMS_SUCCEEDED = '@@orders/FETCH_ORDERS_WITHITEMS_SUCCEEDED',
  FETCH_ORDERS_WITHITEMS_FAILED = '@@orders/FETCH_ORDERS_WITHITEMS_FAILED',

  FETCH_ORDERS_EVENTS = '@@orders/FETCH_ORDERS_EVENTS',
  FETCH_ORDERS_EVENTS_SUCCEEDED = '@@orders/FETCH_ORDERS_EVENTS_SUCCEEDED',
  FETCH_ORDERS_EVENTS_FAILED = '@@orders/FETCH_ORDERS_EVENTS_FAILED',

  IMPORT_ORDERS = '@@orders/IMPORT_ORDERS',
  IMPORT_ORDERS_SUCCEEDED = '@@orders/IMPORT_ORDERS_SUCCEEDED',
  IMPORT_ORDERS_FAILED = '@@orders/IMPORT_ORDERS_FAILED',

  OPEN_ORDERS_FORM = '@@orders/OPEN_ORDERS_FORM',
  CLOSE_ORDERS_FORM = '@@orders/CLOSE_ORDERS_FORM',
}

export interface IFetchOrdersWithItems {
  shallow?: boolean;
}
export interface IFetchOrdersWithItemsSuccess {
  orders: Orders;
  shallow?: boolean;
}
export const fetchOrdersWithItems = createAsyncAction(
  OrdersTypes.FETCH_ORDERS_WITHITEMS,
  OrdersTypes.FETCH_ORDERS_WITHITEMS_SUCCEEDED,
  OrdersTypes.FETCH_ORDERS_WITHITEMS_FAILED
)<IFetchOrdersWithItems, IFetchOrdersWithItemsSuccess, IFailedWithShallow>();

export interface IFetchOrdersEvents {
  floorplanId: string;
}

export interface IFetchOrdersEventsSucceeded {
  floorplanId: string;
  data: IOrdersEvents[];
}

export interface IFetchOrdersEventsFailed {
  error: FetchError;
  floorplanId: string;
}

export const fetchOrdersEvents = createAsyncAction(
  OrdersTypes.FETCH_ORDERS_EVENTS,
  OrdersTypes.FETCH_ORDERS_EVENTS_SUCCEEDED,
  OrdersTypes.FETCH_ORDERS_EVENTS_FAILED
)<IFetchOrdersEvents, IFetchOrdersEventsSucceeded, IFetchOrdersEventsFailed>();

export const importOrders = createAsyncAction(
  OrdersTypes.IMPORT_ORDERS,
  OrdersTypes.IMPORT_ORDERS_SUCCEEDED,
  OrdersTypes.IMPORT_ORDERS_FAILED
)<IImport, Orders, IFailed>();

export const openImportOrdersForm = createAction(
  OrdersTypes.OPEN_ORDERS_FORM,
)();
export const closeImportOrdersForm = createAction(
  OrdersTypes.CLOSE_ORDERS_FORM,
)();
